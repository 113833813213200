<template>
    <div class="BarList">
        <div class="BarList_item"
             @click="callMore(item)"
             v-for="(item, index) in list" :key="index + item.id">
            <div class="imgs">
                <baseImage :url="item[propType.img]"/>
            </div>
            <div class="copywriting">
                <div>
                    <h6>{{item[propType.name]}}</h6>
                    <p>{{item[propType.date]}}</p>
                </div>
                <div class="copy_dec" v-if="type === pageType.course">
                    <i class="iconfont iconrenyuan">{{item[propType.user]}}</i>
                </div>
                <div class="copy_dec" v-else-if="type === pageType.column">
                    <i>所属机构：浙江开放大学</i>
                </div>
                <div class="copy_dec" v-else>
                    <i class="iconfont iconrenyuan">{{item[propType.user]}}</i>
                    <span :class="'status' + item[propType.status]">{{liveStatus[item[propType.status]]}}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import constant from './../../../utils/constant';
export default {
    name: 'BarList',
    components: {},
    props: {
        list: {
            type: Array,
            default () {
                return []
            }
        },
        propType: {
            type: Object,
            default(){
                return {
                    img: 'cover',
                    name: 'name',
                    date: 'startTime',
                    user: 'teacherName',
                    status: 'status'
                }
            }
        },
        type: {
            type: String,
            default: constant.pageType.live
        }
    },
    data () {
        return {
            pageType: constant.pageType,
            liveStatus: constant.liveStatus
        };
    },
    methods: {
        callMore (item) {
            this.$emit('callMore', item);
        }
    },
    created () {
    },
    mounted () {
    },
    watch: {}
}
</script>

<style scoped lang="less">
    .BarList {
        .BarList_item {
            margin-top: 20px;
            padding: 20px;
            background: #FFFFFF;
            box-shadow: 0 2px 16px 0 #D8E7FF;
            border-radius: 12px;
            display: flex;
            .imgs {
                flex: 0 0 240px;
                height: 170px;
                border-radius: 6px;
                overflow: hidden;
            }
            .copywriting {
                margin-left: 20px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                width: 100%;
                h6 {
                    margin: 12px 0 0;
                    font-size: 32px;
                    font-weight: 600;
                    color: #333333;
                    line-height: 32px;
                }
                p {
                    margin-top: 20px;
                    font-size: 20px;
                    font-weight: 400;
                    color: #969696;
                    line-height: 20px;
                }
                .copy_dec {
                    display: flex;
                    justify-content: space-between;
                    i {
                        font-size: 20px;
                        font-weight: 400;
                        color: #969696;
                        line-height: 40px;
                        display: inline-block;
                    }
                    .status1{
                        color: #1A78F9;
                        border-color: #1A78F9;
                    }
                    .status2{
                        color: #F9721A;
                        border-color: #F9721A;
                    }
                    span {
                        text-align: center;
                        display: inline-block;
                        width: 100px;
                        border-radius: 20px;
                        border: 2px solid #969696;
                        font-size: 20px;
                        font-weight: 400;
                        color: #969696;
                        line-height: 40px;
                    }
                }
            }
        }
    }

    .spaces.BarList{
        margin-top: 20px;
        border-radius: 8px;
        .BarList_item{
            box-shadow: none;
            margin-top: 0;
            position: relative;
            border-radius: 0;
            &:before{
                position: absolute;
                content: '';
                height: 2px;
                left: 20px;
                right: 20px;
                top: 0;
                background: #E5E5E5;
            }
            &:first-child:before{
                height: 0
            }
        }
    }
</style>
