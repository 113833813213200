import HttpClient from '@/utils/http'

export default {
    // 获取学生课程列表
    getCourseList(data) {
        return HttpClient.get(`/cloud-livestream/course/queryStudentCourseList`, data)
    },
    // 查询学生直播列表
    getStudentLive(data) {
        return HttpClient.get(`/cloud-livestream/live/getStudentLive`, data)
    }
}